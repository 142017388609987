import React from "react";
import Seo from "../../components/layout/seo";
import Affilatura from "../../components/pages/affilatura";

const data = {
  intro: (
    <p>
      We provide sharpening service for all tools, wood and aluminium, in HM or
      diamond.
      <br />
      <br />
      Please use the contact form below to let us know the type and quantity so
      that we can make an offer.
    </p>
  ),
  blocks: [],
};

const SharpeningServices = ({ location }) => (
  <>
    <Seo title="Sharpening services" desc="" />
    <Affilatura data={data} location={location} />
  </>
);

export default SharpeningServices;
